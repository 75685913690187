import React from "react"
import { Link } from "gatsby"
import { window } from "browser-monads"

import Layout from "../components/layout"
import Progress from "../components/progress"
import ClientOnly from "../components/clientOnly"

import {
  ajax,
  API_CALL_STATUSES,
  getQueryParam,
  getRedirectPathFromUrl,
  getServiceQueryParam,
  isNumeric,
} from "../utils"

import RegisterForm from "../components/register/registerForm"

const validateUserName = name => {
  const usernameRegex = /^([^~!@#$%^&*()<>,?{};':;+=\"\[\]\\/\x60]){1,40}$/m
  return !name || (!isNumeric(name) && usernameRegex.test(name))
}

const SECONDARY_LINK_STYLE = {
  color: "#4da8b5",
  cursor: "pointer",
}

class Register extends React.Component {
  constructor(props) {
    super(props)

    const urlEmail = getQueryParam("email") || ""
    const { firstName, lastName } = urlEmail
      ? this.inferNameFromEmail(urlEmail)
      : { firstName: "", lastName: "" }

    this.grecaptchaRef = React.createRef()
    this.state = {
      email: urlEmail,
      firstName: firstName,
      lastName: lastName,
      password: "",
      confirmPassword: "",
      registerStatus: API_CALL_STATUSES.IDLE,
      registerErrorCode: "",
      registerErrorMsg: "",
      emailReadOnly: urlEmail !== "",
      formTouched: false,
      showCaptchaMessage: false,
      grecaptchaValue: "",
    }

    this.validateInputItem = this.validateInputItem.bind(this)
    this.validateForm = this.validateForm.bind(this)
    this.onBlur = this.onBlur.bind(this)
    this.updateValue = this.updateValue.bind(this)
    this.onCaptchaChange = this.onCaptchaChange.bind(this)
    this.onRegisterSubmit = this.onRegisterSubmit.bind(this)
  }

  onBlur = inputName => {
    const initialValue = this.state[inputName]
    this.setState({
      formTouched: true,
      [inputName]: (initialValue || "").trim(),
    })
  }

  validateForm = () => {
    let isValidForm = true
    ;["email", "firstName", "lastName", "password", "confirmPassword"].forEach(
      itemName => {
        const validationResult = this.validateInputItem(itemName)
        if (!validationResult) {
          isValidForm = false
        }
      }
    )
    return isValidForm
  }

  validateInputItem = inputItemName => {
    const { firstName, lastName, email, password, confirmPassword } = this.state
    let regex = /^[A-Za-z]+$/
    const { allowPlusEmail } = window
    const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    const disallowPlusEmailRegex = /^(([^<>()[\]\\.,;:\s@+"]+(\.[^<>()[\]\\.,;:\s@+"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

    switch (inputItemName) {
      case "firstName":
      case "lastName":
        return validateUserName(firstName) && typeof lastName === "string"
      case "email":
        {
          if (allowPlusEmail) {
            regex = emailRegex
          } else {
            regex = disallowPlusEmailRegex
          }
        }
        return !email || regex.test(email)
      case "password":
        regex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*[\d])(?=.*[$@!*\_\-#%\^&])[A-Za-z\d$@!*\_\-#%\^&]{8,}$/
        return !password || regex.test(password)
      case "confirmPassword":
        return (
          confirmPassword &&
          this.validateInputItem("password") &&
          confirmPassword === password
        )
    }
  }

  getErrorMessage = status => {
    const { email, registerErrorMsg } = this.state
    switch (status) {
      case 409:
        return (
          <div class="text-gray-500">
            <span class="text-red-500">User has already been registered.</span>
            <br />
            <br />
            <Link
              style={SECONDARY_LINK_STYLE}
              to={`/resendVerify/${getRedirectPathFromUrl()}&email=${encodeURIComponent(
                email
              )}`}
            >
              Resend Email
            </Link>
            &nbsp; if you haven't received your verification email or the link
            has expired
            <br />
            <br />
            <Link
              style={SECONDARY_LINK_STYLE}
              to={`/forgotPassword/${getRedirectPathFromUrl()}`}
            >
              Reset Password
            </Link>{" "}
            if you forgot your account password
          </div>
        )
      case 500:
        return "Registration failed due to server error. Please try again later."
      case 400:
        return registerErrorMsg ?? "Bad request"
      default:
        return "Failed to register"
    }
  }

  onRegisterSubmit = e => {
    e.preventDefault()

    let { email, firstName, lastName, password, confirmPassword } = this.state

    if (email !== "" && firstName === "" && lastName === "") {
      const inferredNameParts = this.inferNameFromEmail(email)
      firstName = inferredNameParts?.firstName || email
      lastName = inferredNameParts?.lastName || email
    }

    ajax({
      path: "user/register",
      headers: { path: "/", service: getServiceQueryParam() },
      type: "POST",
      data: {
        email,
        firstName,
        lastName,
        password,
        confirmPassword,
      },
      success: () => {
        this.setState({
          registerStatus: API_CALL_STATUSES.SUCCESS,
        })
      },
      error: (err, res) => {
        //this.grecaptchaRef.current.reset()
        this.setState({
          grecaptchaValue: "",
          registerStatus: API_CALL_STATUSES.ERROR,
          registerErrorCode: err && err.status ? err.status : "",
          registerErrorMsg: res.error,
        })
      },
    })
  }

  onCaptchaChange = grecaptchaValue => {
    this.setState({
      grecaptchaValue,
      showCaptchaMessage: !grecaptchaValue,
    })
  }

  capitalizeFirstLetter = ([first, ...rest], locale = navigator.language) => {
    if (!(first && rest)) {
      return ""
    }
    if (first && !rest) return first.toLocaleUpperCase(locale)

    if (first && rest)
      return first.toLocaleUpperCase(locale) + [rest || ""].join("")
  }

  inferNameFromEmail = email => {
    let nameParts = email
    if (email.indexOf("@") !== -1) {
      const parts = email.split("@")
      nameParts = parts[0]
    }

    const nameSubparts = nameParts.split(/[^A-Za-z0-9]/)
    if (nameSubparts && nameSubparts.length > 1) {
      const firstName = nameSubparts[0]
      let lastName = nameSubparts.slice(1).join(" ")
      lastName = lastName.trim()
      lastName = lastName !== "" ? lastName : "-"

      return { firstName, lastName }
    }

    if (nameSubparts && nameSubparts.length == 1)
      return { firstName: nameSubparts[0], lastName: "-" }

    return { firstName: nameParts, lastName: "-" }
  }

  updateValue = e => {
    const {
      target: {
        dataset: { id },
        value,
      },
    } = e
    e.preventDefault()
    var updatePayload = {
      [id]: value,
    }
    if (id === "email") {
      const { firstName, lastName } = this.inferNameFromEmail(value)
      updatePayload["firstName"] = this.capitalizeFirstLetter(firstName)
      updatePayload["lastName"] =
        this.capitalizeFirstLetter(lastName) == ""
          ? lastName
          : this.capitalizeFirstLetter(lastName)
    }
    this.setState(updatePayload)
  }

  render() {
    const {
      email,
      firstName,
      lastName,
      password,
      confirmPassword,
      emailReadOnly,
      registerStatus,
      registerErrorCode,
      showCaptchaMessage,
      formTouched,
      planData,
    } = this.state

    return (
      <Layout bgimage dark stickyFooter>
        {registerStatus === API_CALL_STATUSES.PROGRESS && <Progress />}

        <ClientOnly className="relative flex flex-col justify-center items-center h-full">
          <React.Fragment>
            <RegisterForm
              onRegisterSubmit={this.onRegisterSubmit}
              onBlur={this.onBlur}
              planData={planData}
              captchaRef={this.grecaptchaRef}
              registerStatus={registerStatus}
              validateInputByType={this.validateInputItem}
              showCaptchaMessage={showCaptchaMessage}
              formTouched={formTouched}
              formData={{
                email,
                firstName,
                lastName,
                password,
                confirmPassword,
                emailReadOnly,
                confirmPassword,
              }}
              errorMessage={this.getErrorMessage(registerErrorCode)}
              validateForm={this.validateForm}
              updateValue={this.updateValue}
              onCaptchaChange={this.onCaptchaChange}
            />
          </React.Fragment>
        </ClientOnly>
      </Layout>
    )
  }
}

export default Register
