import React from "react"
import { Link } from "gatsby"

import { getRedirectPathFromUrl, API_CALL_STATUSES } from "../../utils"

import MailImg from "../../assets/img/mail.png"
import FancyInput from "../FancyInput"
import PasswordInput from "../passwordInputs/PasswordInput"
import RIOLogo from "../../assets/img/rio_wordmark.svg"

const RegisterForm = ({
  registerStatus,
  formData: { emailReadOnly, password, email, confirmPassword },
  errorMessage,
  validateInputByType,
  formTouched,
  validateForm,
  onBlur,
  updateValue,
  onRegisterSubmit,
}) => {
  const isDisabled = formTouched && !validateForm()

  return (
    <div className="container mx-auto flex justify-center items-center h-full">
      <div className="w-full max-w-md">
        <div className="relative flex flex-col w-full p-6 rounded-lg">
          {registerStatus === API_CALL_STATUSES.SUCCESS ? (
            <div className="max-w-md w-full space-y-8 bg-white py-16 px-8">
              <div>
                <img
                  className="mx-auto h-12 w-auto"
                  src={MailImg}
                  alt="Workflow"
                />
                <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
                  Almost Done
                </h2>
                <p className="mt-2 text-center text-sm text-gray-600">
                  <span className="text-xs  text-gray-600 ">
                    Please confirm your email at
                    <span
                      id="successEmail text-gray-900"
                      style={{ padding: "0 4px" }}
                    >
                      {email}
                    </span>
                    to continue with account creation.
                    <br />
                    Haven't received an email?
                    <br />
                    <i>Please also check your spam folder</i> or{" "}
                    <Link
                      to={`/resendVerify/${getRedirectPathFromUrl()}&email=${encodeURIComponent(
                        email
                      )}`}
                      className="registerResend textBtn"
                    >
                      <span class="text-red-500 hover:text-red-600">
                        {" "}
                        resend verification email
                      </span>
                    </Link>
                  </span>
                </p>
              </div>
            </div>
          ) : (
            <div className="w-full max-w-md bg-white shadow-lg rounded-lg p-8">
              <div className="text-center">
                <img
                  className="mx-auto h-12 w-auto"
                  src={RIOLogo}
                  alt="Rapyuta Robotics"
                />
              </div>

              <form
                id="registerForm"
                onSubmit={onRegisterSubmit}
                className="mt-8 space-y-6"
              >
                <div className="space-y-4">
                  <FancyInput
                    data-id="email"
                    type="email"
                    name="email"
                    placeholder="Email address"
                    id="emailRegister"
                    required
                    readOnly={emailReadOnly}
                    tabIndex={emailReadOnly ? -1 : 0}
                    onBlur={() => onBlur("email")}
                    value={email}
                    onChange={updateValue}
                  />
                  <PasswordInput
                    data-id="password"
                    placeholder="Password"
                    name="password"
                    id="passwordRegister"
                    required
                    value={password}
                    onChange={updateValue}
                    onBlur={() => onBlur("password")}
                  />
                  <PasswordInput
                    data-id="confirmPassword"
                    placeholder="Confirm password"
                    name="confirmPassword"
                    id="confirmPasswordRegister"
                    required
                    value={confirmPassword}
                    onChange={updateValue}
                    onBlur={() => onBlur("confirmPassword")}
                    valid={validateInputByType("confirmPassword")}
                  />
                  {formTouched && !validateInputByType("email") && (
                    <p className="text-xs text-red-600">
                      Not a valid email address
                    </p>
                  )}
                  {formTouched && !validateInputByType("password") && (
                    <p className="text-xs text-red-600">
                      Password must be at least 8 characters long and include
                      one uppercase letter, one lowercase letter, one number,
                      and one special character.
                    </p>
                  )}
                  {errorMessage &&
                    registerStatus === API_CALL_STATUSES.ERROR && (
                      <p className="text-xs text-red-600">{errorMessage}</p>
                    )}
                </div>

                <button
                  type="submit"
                  disabled={
                    isDisabled || registerStatus === API_CALL_STATUSES.PROGRESS
                  }
                  className={`w-full py-2 px-4 font-bold rounded-md ${
                    isDisabled || registerStatus === API_CALL_STATUSES.PROGRESS
                      ? "bg-gray-400 text-gray-200 cursor-not-allowed"
                      : "bg-red-600 text-white hover:bg-red-700"
                  }`}
                >
                  Sign Up
                </button>
              </form>
            </div>
          )}
          <div className="flex bg-gray-50 py-4 px-4 my-10 rounded flex items-center">
            <p className="flex flex-grow text-xs  text-gray-600">
              Already have an account?
            </p>
            <a
              className="font-semibold text-sm text-red-700"
              id="loginLink"
              href="/login/"
            >
              Sign In
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default RegisterForm
